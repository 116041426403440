<template>
  <div>
    <div class="block_upload" v-if="step === 0">
      <div class="contour">
        <input ref="inputFile" type="file" @change="getFileData">
        <div>
          Glisse ton ficher ICI
        </div>
      </div>
    </div>
    <div class="block_loading" v-if="step === 1">
      <div class="top">Chargement en cours</div>
      <div class="bottom">Veuillez patienter quelques instants</div>
    </div>
    <div class="block_choose" v-if="step === 2">
      Vérification du fichier
    </div>
    <div class="block_choose" v-if="step === 3">
      <div class="top">Tu peux choisir les chaines</div>
      <button @click="download">Télecharger la sélection</button>
      <input type="text" v-model="filename">
      <br/>
      <input type="text" v-model="search" placeholder="Rechercher">
      <button @click="setTree">Lancer la recherche</button>
      {{ search }}
      <ul>
        <li :key="group1Data.name" v-for="group1Data in tree">
          <MyCheckbox type="checkbox"
                      :value="group1Data.selectedValue"
                      @click="toggleSelectedLevel1(group1Data)"/>
          <div class="textContent" @click="group1Data.isOpen = !group1Data.isOpen">
            {{ group1Data.name }}
          </div>
          <ul v-if="group1Data.isOpen">
            <li :key="group2Data.name" v-for="group2Data in group1Data.childs">
              <MyCheckbox type="checkbox"
                          :value="group2Data.selectedValue"
                          @click="toggleSelectedLevel2(group2Data, group1Data)"/>
              <div class="textContent" @click="group2Data.isOpen = !group2Data.isOpen">
                {{ group2Data.name }}
              </div>
              <ul v-if="group2Data.isOpen">
                <li :key="keyGroup3" v-for="(group3Data, keyGroup3) in group2Data.childs">
                  <input
                      type="checkbox"
                      v-model="group3Data[0].selected"
                      @change="recheck(group1Data)"
                  />
<!--                  <img height="20px" :src="group3Data[0].tvgLogo" alt="">-->
                  {{ keyGroup3 }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import TreeItem from '../components/TreeItem.vue';
import MyCheckbox from '../components/MyCheckbox.vue';

export default {
  components: {
    // TreeItem,
    MyCheckbox,
  },
  data() {
    return {
      fileContent: '',
      textLines: [],
      parsedLines: [],
      groupTitleList: [],
      step: 0,
      openLvl1: [],
      openLvl2: [],
      openLvl3: [],
      tree: {},
      filename: '',
      search: '',
    };
  },
  methods: {
    recheck(group1Data) {
      const keys1 = Object.keys(group1Data.childs);
      let foundOne1 = false;
      let foundOneNotSelected1 = false;
      for (let i = 0; i < keys1.length; i += 1) {
        const currentVal = group1Data.childs[keys1[i]];
        const keys2 = Object.keys(currentVal.childs);
        let foundOne2 = false;
        let foundOneNotSelected2 = false;
        for (let j = 0; j < keys2.length; j += 1) {
          const currentVal2 = currentVal.childs[keys2[j]];
          if (currentVal2[0].selected) {
            foundOne1 = true;
            foundOne2 = true;
          } else {
            foundOneNotSelected1 = true;
            foundOneNotSelected2 = true;
          }
        }
        if (foundOne2 && foundOneNotSelected2) {
          // eslint-disable-next-line no-param-reassign
          currentVal.selectedValue = 2;
        } else if (foundOne2 && !foundOneNotSelected2) {
          // eslint-disable-next-line no-param-reassign
          currentVal.selectedValue = 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          currentVal.selectedValue = 0;
        }
      }
      if (foundOne1 && foundOneNotSelected1) {
        // eslint-disable-next-line no-param-reassign
        group1Data.selectedValue = 2;
      } else if (foundOne1 && !foundOneNotSelected1) {
        // eslint-disable-next-line no-param-reassign
        group1Data.selectedValue = 1;
      } else {
        // eslint-disable-next-line no-param-reassign
        group1Data.selectedValue = 0;
      }
    },
    download() {
      let str = '#EXTM3U\n';

      for (let i = 0; i < this.tree.length; i += 1) {
        const values2 = this.tree[i].childs;
        for (let j = 0; j < values2.length; j += 1) {
          const values3 = Object.values(values2[j].childs);
          for (let k = 0; k < values3.length; k += 1) {
            const values4 = values3[k];
            if (values4[0].selected) {
              str += values4[0].fullString;
              str += '\n';
              str += values4[1];
              str += '\n';
            }
          }
        }
      }
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(str)}`);
      element.setAttribute('download', this.filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      // console.log(str);
    },
    toggleSelectedLevel1(groupData) {
      const checkLevel = groupData.selectedValue;
      const valueToSet = checkLevel !== 1;
      const values = Object.values(groupData.childs);
      for (let i = 0; i < values.length; i += 1) {
        const values2 = Object.values(values[i].childs);
        for (let j = 0; j < values2.length; j += 1) {
          values2[j][0].selected = valueToSet;
        }
      }
      this.$nextTick(() => this.recheck(groupData));
    },
    toggleSelectedLevel2(groupData, groupDataMaster) {
      const checkLevel2 = groupData.selectedValue;
      const valueToSet = checkLevel2 !== 1;
      const values = Object.values(groupData.childs);
      for (let i = 0; i < values.length; i += 1) {
        values[i][0].selected = valueToSet;
      }
      // this.recheck(groupDataMaster);
      this.$nextTick(() => this.recheck(groupDataMaster));
    },
    setTree() {
      const rt = {};
      const normalizedSearch = this.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      const toFind = this.search.length > 0
        ? this.parsedLines.filter(([elm]) => {
          const normalizedTvgName = elm.tvgName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
          return (normalizedTvgName.includes(normalizedSearch));
        })
        : this.parsedLines;
      toFind.forEach((elmAll) => {
        const elmFirstLine = elmAll[0];
        if (rt[elmFirstLine.groupTitleSub1] === undefined) {
          rt[elmFirstLine.groupTitleSub1] = {};
        }
        if (rt[elmFirstLine.groupTitleSub1][elmFirstLine.groupTitleSub2] === undefined) {
          rt[elmFirstLine.groupTitleSub1][elmFirstLine.groupTitleSub2] = {};
        }
        if (rt[elmFirstLine.groupTitleSub1][elmFirstLine.groupTitleSub2][elmFirstLine.tvgName]
            === undefined) {
          // eslint-disable-next-line max-len
          rt[elmFirstLine.groupTitleSub1][elmFirstLine.groupTitleSub2][elmFirstLine.tvgName] = elmAll;
        }
      });
      const rt2 = Object.entries(rt).map(([name, childs]) => ({
        name,
        isOpen: false,
        selectedValue: 0,
        childs: Object.entries(childs).map(([name2, childs2]) => ({
          name: name2,
          isOpen: false,
          selectedValue: 0,
          childs: childs2,
        })),
      }));
      this.$set(this, 'tree', rt2);
    },
    checkFile() {
      // const regex = /(tvg-id="([^"]*)")/;
      const regexList = {
        groupTitle: /(group-title="([^"]*)")/,
        tvgName: /(tvg-name="([^"]*)")/,
        tvgLogo: /(tvg-logo="([^"]*)")/,
      };

      this.textLines = this.fileContent.split('\n');
      this.fileContent = '';

      let actuLine = 0;
      while (
        this.textLines[actuLine] !== undefined
        && this.textLines[actuLine].trim() === ''
      ) {
        actuLine += 1;
      }
      if (this.textLines[actuLine].trim() !== '#EXTM3U') {
        alert("Le ficher n'est pas valide");
        this.step = 0;
        return;
      }
      actuLine += 1;
      let mode = 0;
      for (let i = actuLine; i < this.textLines.length; i += 1) {
        if (this.textLines[i].trim().length === 0) {
          // eslint-disable-next-line no-continue
          continue;
        }
        if (mode === 0) {
          if (this.textLines[i][0] !== '#') {
            alert('Il semble y avoir un problème avec ce fichier');
            this.step = 0;
            return;
          }
          let groupTitle = this.textLines[i].match(regexList.groupTitle)[2] || '';
          if (groupTitle.trim() === '') {
            groupTitle = this.textLines[i].match(regexList.tvgName)[2] || '';
          }
          let groupTitlePrepared = groupTitle;
          if (groupTitlePrepared[0] === '|') {
            groupTitlePrepared = groupTitlePrepared.slice(1);
          }
          const groupTitleSub = groupTitlePrepared.split('|');
          const nElm = {
            groupTitle,
            groupTitleSub1: groupTitleSub[0].trim(),
            groupTitleSub2: (groupTitleSub[1] || '-').trim(),
            tvgName: (this.textLines[i].match(regexList.tvgName)[2] || '').trim(),
            tvgLogo: (this.textLines[i].match(regexList.tvgLogo)[2] || '').trim(),
            fullString: this.textLines[i],
            selected: false,
          };
          if (nElm.groupTitleSub1 === '') {
            nElm.groupTitleSub1 = '???';
          }
          if (!this.groupTitleList.includes(nElm.groupTitleSub1)) {
            this.groupTitleList.push(nElm.groupTitleSub1);
          }
          this.parsedLines.push([nElm]);
          mode = 1;
        } else if (mode === 1) {
          if (this.textLines[i][0] === '#') {
            alert('Il semble y avoir un problème avec ce fichier');
            this.step = 0;
            return;
          }
          this.parsedLines[this.parsedLines.length - 1].push(this.textLines[i]);
          mode = 0;
        }
      }
      this.setTree();
      this.step = 3;
    },
    getFileData(ee) {
      const file = ee.target.files[0];
      this.filename = file.name.split('.').slice(0, -1).join('.');
      const dd = (new Date()).toLocaleDateString();
      dd.replace('/', '_');
      this.filename += `_filtre_${dd}.m3u`;
      if (file) {
        this.step = 1;
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = (evt) => {
          this.fileContent = evt.target.result;
          this.step = 2;
          this.checkFile();
        };
      }
    },
  },
};
</script>

<style>
.block_upload {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contour {
  position: relative;
  border:7px dashed #57a0e7;
  border-radius:30px;
  width: 500px;
  height: 300px;
}
.contour > input {

  opacity:0;
  width: 100%;
  height: 100%;
}
.contour > div {
  position: absolute;
  top: 0px;
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #57a0e7;
  pointer-events: none;
}
.block_loading > .top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #57a0e7;
}
.block_choose > .top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #57a0e7;
}
.block_choose li {
  text-align:left;
}
.textContent {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  color: #57a0e7;
}
.textContent:hover {
  text-decoration: underline;
}
</style>
