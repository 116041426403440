<template>
  <div
    class="myCheck"
    @click.stop="$emit('click', $event)"
  >
    <input
        ref="check_inde"
        type="checkbox"
    />
  </div>
</template>

<script>
export default {
  data() {
    return ({
      localValue: false,
    });
  },
  props: {
    value: {},
  },
  computed: {
    getValue() {
      return (this.value);
    },
  },
  watch: {
    getValue(value) {
      if (value === 2) {
        this.$refs.check_inde.indeterminate = true;
      } else {
        this.$refs.check_inde.indeterminate = false;
        this.$refs.check_inde.checked = value === 1;
      }
    },
  },
  mounted() {
    if (this.value === 2) {
      this.$refs.check_inde.indeterminate = true;
    } else {
      this.$refs.check_inde.indeterminate = false;
      this.$refs.check_inde.checked = this.value === 1;
    }
  },
};
</script>

<style>
.myCheck {
  display: inline-block;
}
.myCheck input {
  pointer-events: none;
}
</style>
